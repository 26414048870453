$arrowHeight: 4px;
$arrowPadding: 4px;

.popper {
  z-index: 1;

  &[data-popper-placement*="bottom"] {
    & > div {
      padding-top: $arrowHeight + $arrowPadding;
    }
    .arrow {
      margin-top: $arrowPadding;
      top: 0;
      left: 0;
      width: 3em;
      height: 1em;
      &::before {
        border-width: 0 1em 1em 1em;
        border-color: transparent transparent currentColor transparent;
      }
    }
  }
  &[data-popper-placement*="top"] {
    & > div {
      padding-bottom: $arrowHeight + $arrowPadding;
    }
    .arrow {
      margin-bottom: $arrowPadding;
      bottom: 0;
      left: 0;
      width: 3em;
      height: 1em;
      &::before {
        border-width: 1em 1em 0 1em;
        border-color: currentColor transparent transparent transparent;
      }
    }
  }
  &[data-popper-placement*="right"] {
    & > div {
      padding-left: $arrowHeight + $arrowPadding;
    }
    .arrow {
      margin-left: $arrowPadding;
      left: 0;
      height: 3em;
      width: 1em;
      &::before {
        border-width: 1em 1em 1em 0;
        border-color: transparent currentColor transparent transparent;
      }
    }
  }
  &[data-popper-placement*="left"] {
    & > div {
      padding-right: $arrowHeight + $arrowPadding;
    }
    .arrow {
      margin-right: $arrowPadding;
      right: 0;
      height: 3em;
      width: 1em;
      &::before {
        border-width: 1em 0 1em 1em;
        border-color: transparent transparent transparent currentColor;
      }
    }
  }
}

.arrow {
  position: absolute;
  font-size: $arrowHeight;
  width: 3em;
  height: 3em;

  &::before {
    content: "";
    margin: auto;
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
  }
}
