%normalBg {
  background-image: linear-gradient(89.81deg, #1d4ed8 0.17%, #7c3aed 99.83%);
}

%hoverBg {
  background-image: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.1),
      rgba(0, 0, 0, 0.1)
    ),
    linear-gradient(89.81deg, #1d4ed8 0.17%, #7c3aed 99.83%);
}

%activeBg {
  background-image: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.2),
      rgba(0, 0, 0, 0.2)
    ),
    linear-gradient(89.81deg, #1d4ed8 0.17%, #7c3aed 99.83%);
}

%disableBg {
  background-image: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.2),
      rgba(0, 0, 0, 0.2)
    ),
    linear-gradient(89.81deg, #1d4ed8 0.17%, #7c3aed 99.83%);
}

.buttonBg {
  @extend %normalBg;

  &:hover {
    @extend %hoverBg;
  }

  &:active {
    @extend %activeBg;
  }

  &:disabled,
  &[disabled],
  &[aria-disabled="true"] {
    @extend %disableBg;
  }
}
