.article {
  font-size: 16px;
  line-height: 24px;

  :global(h2) {
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    margin-top: 64px;
    margin-bottom: 32px;

    &:first-child {
      @apply mt-2;
    }
  }

  > :global(iframe) {
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  > :global(*) {
    margin-bottom: 1.5rem;
  }
}
