@use "sass:math";

$centerIconSize: 36px;
$stepContentWidth: 100px;

.stepList {
  display: flex;
}

.archivedStep {
}
.currentStep {
}
.futureStep {
}
.step {
  &:not(:last-child) {
    @apply flex-1;
  }
}

.stepContentContainer {
  @apply flex flex-col items-center;
  width: $stepContentWidth;
}

%stepMainContent {
  .futureStep & {
    @apply opacity-20;
  }
}

.step {
  position: relative;
}
.centerContent {
  display: flex;
  align-items: center;
}
.stepLine {
  $iconBothSideMargin: 14px;
  $lineInset: 15px;

  position: absolute;
  left: $stepContentWidth - $lineInset;
  border-top: 1px solid #7c3aed;
  width: calc(100% - #{$stepContentWidth - $lineInset * 2});

  .step:last-child & {
    @apply hidden;
  }

  .currentStep &,
  .futureStep & {
    @apply border-gray-200 opacity-10;
  }
}

%stepText {
  @extend %stepMainContent;
  @apply text-sm leading-5 text-center;
}
.aboveText {
  @extend %stepText;
  @apply text-gray-200 font-semibold;

  .archivedStep & {
    @apply opacity-20;
  }
}
.belowText {
  @extend %stepText;
  @apply text-gray-400;

  .currentStep & {
    @apply font-semibold text-gray-200;
  }
}

.centerIcon {
  @extend %stepMainContent;
  @apply my-2.5 text-base leading-6 font-semibold;
  @apply flex items-center justify-center;
  width: $centerIconSize;
  height: $centerIconSize;
  overflow: hidden;
  border-radius: 18px;
  background-size: 100% 100%;
  background-repeat: no-repeat;

  .archivedStep & {
    font-size: 0;
    background-image: url("check.svg");
  }

  .currentStep & {
    @apply text-white;
    background-image: linear-gradient(89.81deg, #1d4ed8 0.17%, #a855f7 99.83%);
  }

  .futureStep & {
    color: #e5e7eb; // same as background-image color
    background-image: url("cycle.svg");
  }
}
